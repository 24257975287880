import React from "react"
import HeaderCategory from "../components/header"
import PrintDirectory from "../components/printdirectory"
import Footer from "../components/footer"
import SeO from "../components/SEO"
const printDirectoryTemplate = ({ pageContext }) => {
  return (
    <div>
      <SeO title={`ReadDirectory`} />
      <HeaderCategory />
      <PrintDirectory
        hash={pageContext.params.hash}
        refer_path={pageContext.params.refer_path}
        url={pageContext.params.url}
        title={pageContext.params.title}
        download={pageContext.params.download_url}
      />
      <Footer />
    </div>
  )
}

export default printDirectoryTemplate